import React, { useState } from "react";

// import DOMPurify from "dompurify";

//ESTILS
import "./UnfoldingItem.css";

const UnfoldingItem = ({ teachers, title, text, item, works }) => {
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      className={
        !isClicked
          ? "unfoldingItem__container"
          : "unfoldingItem__container clicked"
      }
      onClick={() => {
        setIsClicked(!isClicked);
      }}
    >
      <div className="unfoldingItem__wrapper">
        <span className="unfoldingItem__wrapper-title">{title}</span>
        <div className="unfoldingItem__icon">+</div>
      </div>
      {isClicked && teachers !== undefined && (
        <>
          {teachers?.map((item) => {
            return (
              <p>
                <span className="unfoldingItem__teacher-name">{item.name}</span>
                {item.p1}
                {item.name === "Xavier Rambla" && (
                  <>
                    <a
                      href={item.link1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.link1}
                    </a>
                    <span> {item.p2}</span>
                    <a
                      href={item.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.link2}
                    </a>
                  </>
                )}
                {item.name === "Rosario Scandurra" && (
                  <>
                    <span>{item.p2}</span>
                    <a
                      href={item.link1}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      here
                    </a>
                    <span> {item.p3}</span>
                    <a
                      href={item.link2}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.link2}
                    </a>
                  </>
                )}
                {item.name === "Dr. Yuri Kazepov " && (
                  <>
                    <span>{item.p2}</span>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {item.link}
                    </a>
                  </>
                )}
                {item.name === "Narcís Bosc " && (
                  <>
                    <a
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {item.link}
                    </a>
                  </>
                )}
                {item.name === "Pepka Boyadjieva " && (
                  <>
                    <span style={{ fontFamily: "Arvo, italic" }}>
                      {item.italic}
                    </span>
                    <span>{item.p2}</span>
                  </>
                )}
              </p>
            );
          })}
        </>
      )}
      {isClicked && (
        <>
          <p>{text}</p>
          {item!==undefined&& <div
            className="unfolding-item__item-container"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <span>{item.title}</span>
            <p>{item.text}</p>
            <a href={item.pdf} download>
              {item.pdfTitle}
            </a>
          </div>}
          {/* {works !== undefined &&
            works.map((work) => {

              let htmlContent=work.content.rendered

              const sanitizedContent = DOMPurify.sanitize(htmlContent);

              return (
                <>
                <p>{work.title.rendered}</p>
                <div
                  className="unfolding-item__item-container"
                  onClick={(event) => {
                    event.stopPropagation();
                  }}
                  dangerouslySetInnerHTML={{__html:sanitizedContent}}
                >
                
                </div>
                </>
              );
            })} */}
        </>
      )}
    </div>
  );
};

export default UnfoldingItem;

