import React, { useEffect, useState } from "react";
import FirstSection from "../FirstSection/FirstSection";
import BlogCard from "./BlogCard/BlogCard";
import BlogEntry from "./BlogEntry/BlogEntry";
import "./Blog.css";
const Blog = ({ isClicked, setIsClicked }) => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [content, setContent] = useState({});
const [allPosts,setAllPosts]=useState([])
  useEffect(() => {
    fetch(
      "https://public-api.wordpress.com/wp/v2/sites/ecastellsricart.wordpress.com/posts"
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const newData= data.filter((item)=>
          {  console.log(item.categories[0])
           return item.categories[0]!==17291201}
          )

       

        setPosts(newData);

        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, []);

  function cleanHTMLString(htmlString) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error loading posts: {error.message}</p>;
  if (isClicked === false) {
    return (
      <div className="blog__container">
        <FirstSection title={"Blog"} />
        <div className="blog__cards-wrapper">
          {posts.map((post) => (
            <BlogCard
              title={cleanHTMLString(post.title.rendered)}
              text={post.content.rendered}
              image={post.jetpack_featured_media_url}
              setIsClicked={setIsClicked}
              setContent={setContent}
              postId={post.id}
            />
          ))}
        </div>
      </div>
    );
  } else if (isClicked === true) {
    return (
      <BlogEntry
        title={cleanHTMLString(content.title)}
        text={content.text}
        image={content.image}
        postId={content.postId}
      />
    );
  }
};

export default Blog;
